<template>
  <v-card>
    <v-card-title class="text-h6"> Xác nhận hàng hoàn </v-card-title>
    <v-card-text>UID: {{ item.sku_uid }}</v-card-text>

    <v-card-text>
      <v-text-field
        v-model="roll_reason"
        class="c-input-small"
        @keyup.enter="nextInput"
        label="Nguyên nhân đổi trả *"
        autofocus
        dense
        outlined
        clearable
        hide-details
      ></v-text-field>
    </v-card-text>

    <v-card-text>
      <v-text-field
        v-model="basket_code"
        ref="refInputBasket"
        class="c-input-small"
        @keyup.enter="getBasket"
        label="Mã sọt chứa sản phẩm đổi trả *"
        dense
        outlined
        clearable
        hide-details
      ></v-text-field>
    </v-card-text>

    <v-card-text>
      <div class="font-weight-medium" style="height: 26px">
        SP đủ điều kiện kinh doanh không?
      </div>
      <v-radio-group
        v-model="roll_status"
        single-line
        dense
        hide-details
        row
        class="mt-0"
      >
        <v-radio
          v-for="item in statusOptions"
          :key="`t_${item.value}`"
          :label="item.text"
          :value="item.value"
        ></v-radio>
      </v-radio-group>
    </v-card-text>

    <v-card-text v-if="!roll_status">
      <v-text-field
        v-model="roll_note"
        class="c-input-small"
        label="Ghi chú *"
        ref="refInputNote"
        dense
        outlined
        clearable
        hide-details
      ></v-text-field>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="cancel"> Hủy </v-btn>
      <v-btn
        color="green darken-1"
        text
        @click="confirm"
        :disabled="isDisabledBtn"
      >
        Xác nhận
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";
export default {
  name: "ConfirmReturnItem",
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    isLoading: false,
    basket_code: null,
    id_basket: null,
    roll_reason: null,
    roll_status: 1,
    roll_note: null,
    statusOptions: [
      {
        text: "Có",
        value: 1,
      },
      {
        text: "Không",
        value: 0,
      },
    ],
  }),
  computed: {
    isDisabledBtn() {
      return (
        !this.roll_reason ||
        !this.id_basket ||
        (!this.roll_status && !this.roll_note)
      );
    },
    reason() {
      const txt = [this.roll_reason];
      if (!this.roll_status) {
        txt.push(this.roll_note);
      }
      return txt.join(" ");
    },
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    async getBasket() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        const { data } = await httpClient.post("/get-basket-for-return", {
          code: this.basket_code,
        });
        this.id_basket = data.id;
        this.isLoading = false;
      } catch (e) {
        this.$root.$emit("playErrorAudio");
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.basket_code = null;
        this.id_basket = null;
        this.isLoading = false;
      }
    },
    async confirm() {
      this.$emit("confirm", {
        ...this.item,
        roll_reason: this.reason,
        id_basket: this.id_basket,
        roll_status: this.roll_status,
      });
      this.cancel();
    },
    printStamp() {},
    nextInput() {
      this.$refs.refInputBasket.focus();
    },
    nextInputNote() {
      if (!this.roll_status) {
        this.$refs.refInputNote.focus();
      }
    },
  },
};
</script>

<style scoped></style>
